import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { makeUseAxiosHook } from 'app/redux/store';

// Action Types
export enum AuthActionTypes {
  LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
  LOGOUT_ERROR = 'LOGOUT_ERROR',
}

// Action Interfaces
interface LogoutSuccessAction {
  type: AuthActionTypes.LOGOUT_SUCCESS;
  payload: undefined;
}

interface LogoutErrorAction {
  type: AuthActionTypes.LOGOUT_ERROR;
  payload: string;
}

type AuthActions = LogoutSuccessAction | LogoutErrorAction;

// Callback types
type OnSuccessCallback = () => void;
type OnErrorCallback = (error: string) => void;

interface LogoutCallbacks {
  onSuccess?: OnSuccessCallback;
  onError?: OnErrorCallback;
}

// Hook return type
interface UseLogoutReturn {
  logout: (callbacks?: LogoutCallbacks) => Promise<void>;
  loading: boolean;
}

export const useLogout = (): UseLogoutReturn => {
  const dispatch = useDispatch();
  
  const [{ loading }, createLogout] = makeUseAxiosHook('default', {
    manual: true,
  })({
    url: '/api/v1/logout',
    method: 'POST',
  });

  const logout = useCallback(async (callbacks?: LogoutCallbacks): Promise<void> => {
    try {
      await createLogout();
      
      // Clear storage first to ensure clean state
      localStorage.removeItem('token');
      sessionStorage.clear(); // Clear any session data
      
      // Dispatch logout success
      dispatch({
        type: AuthActionTypes.LOGOUT_SUCCESS,
        payload: undefined,
      });

      // Execute success callback if provided
      callbacks?.onSuccess?.();
      
    } catch (error) {
      // Handle logout error
      const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
      console.error('Logout failed:', errorMessage);
      
      dispatch({
        type: AuthActionTypes.LOGOUT_SUCCESS,
        payload: undefined,
      });

      // Execute error callback if provided
      callbacks?.onError?.(errorMessage);
    }
  }, [createLogout, dispatch]); // Fixed dependency array

  return {
    logout,
    loading,
  };
};