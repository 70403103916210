import { styled, Text, useSx, View } from 'dripsy'
import { useCalculateFontSize } from 'app/redux/hooks/use-calculate-font-size'
import { Button } from 'app/components/button'
import { Icon } from 'app/components/icon/icon'
import { useRouter } from 'solito/router'

const TitleText = styled(Text)({
  fontFamily: 'Merriweather-Bold',
})

const TextParagraph = styled(Text)({
  fontFamily: 'Montserrat',
})

const ContactUs = () => {
  const sx = useSx()
  const getFontSize = useCalculateFontSize()
  const { push } = useRouter()

  return (
    <View
      style={sx({
        flexDirection: 'row',
        width: '100%',
        backgroundColor: '$primary',
        minHeight: ['50vh', '50vh', '60vh', '70vh'],
        height: '100%',
        paddingLeft: ['5%', '10%'],
        paddingRight: ['5%', 0],
        paddingBottom: 10,
        paddingTop: 10,
        position: 'relative',
        backgroundImage: `url(${require('../../assets/homePageLogo.webp')})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'right',
        backgroundPositionY: 'center',
      })}
    >
      <View
        style={sx({
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'center',
          maxWidth: 600,
        })}
      >
        <TitleText
          style={sx({
            fontSize: getFontSize(100, 30),
            fontWeight: '700',
            textAlign: 'left',
            color: '#FFF',
          })}
        >
          Turn Your Home Design Vision into Reality with AI-Powered DIY Tools
        </TitleText>
        <View style={sx({ height: [20, 40] })} />
        <TextParagraph
          style={sx({
            fontSize: getFontSize(50, 18),
            fontWeight: '500',
            textAlign: 'left',
            color: '#FFF',
          })}
        >
          Visualize, Design, and Build Your Dream Space—No Expertise Needed.
        </TextParagraph>
        <View style={sx({ height: [20, 40] })} />
        <Button
          style={sx({
            height: [30, 40],
            maxWidth: 200,
            backgroundColor: '#2D1D1D',
            padding: [5, 10],
          })}
          textStyle={{
            fontSize: 14,
          }}
          onPress={() => {
            push('/support')
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Text
              style={{
                color: '#FFF',
                marginRight: 20,
              }}
            >
              Contact Us
            </Text>
            <Icon
              name={'arrow'}
              fill={['#FFF']}
              style={{
                transform: [{ rotate: '90deg' }],
              }}
            />
          </View>
        </Button>
      </View>
    </View>
  )
}

export default ContactUs
