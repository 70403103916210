import AsyncStorage from '@react-native-async-storage/async-storage';
import { config } from 'app/redux/config';
import cookie from 'cookie';
import Axios from 'axios';
import { Buffer } from 'buffer';


// export const validEmail = (email?: string) => {
//   const reg = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;
//   return email ? reg.test(email) : false;
// };

// export const validPhone = (phone?: string | number) => {
//   const reg = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
//   return phone ? reg.test(`${phone}`) : false;
// };

export const isValidEmail = (email: any) => {
  const reg = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;
  return email ? reg.test(email) : false;
};

export const isValidPhone = (phone: any) => {
  return phone.match(/^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/);
};

export const getMobileOperatingSystem = () => {
  if (typeof window !== 'undefined' && navigator) {
    const userAgent = navigator.userAgent || navigator.vendor;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }

    if (/android/i.test(userAgent)) {
      return 'Android';
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return 'iOS';
    }
  }

  return 'unknown';
};



export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16)?.replace('-', '');
  });
};

export const validUpi = (upi) => {
  const reg = /^[\w.-]+@[\w.-]+$/;
  return reg.test(upi);
};

export const getAsyncStorageData = async (storeId: string) => {
  if(typeof window === 'undefined') return
    try {
      const value = await AsyncStorage.getItem(storeId);
      if (value !== null) {
        // value previously stored
        return value;
      }
    } catch (e) {
      // error reading value
      return null;
    }
};

export const setAsyncStorageData = async (storeId: string, data: string) => {
  if(typeof window === 'undefined') return
    try {
      await AsyncStorage.setItem(storeId, data);
    } catch (e) {
      // saving error
    }
};

// token set in cookie
export const setCookie = (value: string, key?: string) => {
  if (typeof document !== undefined && typeof window !== undefined)
    document.cookie = cookie.serialize(key || config.authTokenName, value, {
      maxAge: 365 * 24 * 60 * 60 * 10,
      path: '/',
      domain: window.location.hostname
        ? `.${window.location.hostname}`
        : config.cookie.domain,
    });
};

export const getCookie = (name: string) => {
  if (typeof window === 'undefined') return '';
  const nameEQ = name + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    if (c) {
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
  }
  return null;
};

export const getUserRoleName = (role: number | string) => {
  switch (Number(role)) {
    case 1:
      return 'Student';
    case 7:
      return 'Faculty';
    case 3:
      return 'Ambassador';
    case 32767:
      return 'Admin';
    case 65535:
      return 'Super Admin';
    case 31:
      return 'Verified Faculty';
    case 15:
      return 'Content Editor';
    default:
      return 'Student';
  }
};

export const validatePassword = (
  password: string | undefined,
  confirmPassword: string | undefined
) => {
  const special = /[!@#$%^&*(),.?":{}|<>]/g;
  const lowerCaseLetters = /[a-z]/g;
  const upperCaseLetters = /[A-Z]/g;
  const numbers = /[0-9]/g;
  const charactersLength = 8;
  const obj: {
    error: boolean;
    message: string;
    isErrorPwd: boolean;
    isErrorConfirmPwd: boolean;
  } = {
    error: true,
    message: '',
    isErrorPwd: false,
    isErrorConfirmPwd: false,
  };
  if (!password || password.length === 0) {
    obj.message = 'Please enter a password';
    obj.isErrorPwd = true;
    obj.error = true;
  } else if (password.length > 32) {
    obj.message = 'Password must be of 8-32 characters';
    obj.isErrorPwd = true;
    obj.error = true;
  } else if (!password.match(lowerCaseLetters)) {
    obj.message = 'Please enter one lowercase letter';
    obj.isErrorPwd = true;
    obj.error = true;
  } else if (!password.match(upperCaseLetters)) {
    obj.message = 'Please enter one uppercase letter';
    obj.isErrorPwd = true;
    obj.error = true;
  } else if (!password.match(numbers)) {
    obj.message = 'Please enter one number';
    obj.isErrorPwd = true;
    obj.error = true;
  } else if (!password.match(special)) {
    obj.message = 'Please enter at least special character';
    obj.isErrorPwd = true;
    obj.error = true;
  } else if (password && !(password.length >= charactersLength)) {
    obj.message = 'Password should be minimum 8 character';
    obj.isErrorPwd = true;
    obj.error = true;
  } else if (!confirmPassword || confirmPassword.length === 0) {
    obj.message = 'Please verify and re-enter your password';
    obj.isErrorPwd = false;
    obj.isErrorConfirmPwd = true;
    obj.error = true;
  } else if (password !== confirmPassword) {
    obj.message = 'Password is not matched';
    obj.isErrorPwd = false;
    obj.isErrorConfirmPwd = true;
    obj.error = true;
  } else obj.error = false;

  return obj;
};

export const getCountSuffix = (count: number, decimalPoint: number = 2) => {
  let value = '';
  if (count >= 0 && count <= 999) {
    value = `${count}`;
  } else if (count >= 1000000) {
    value = `${
      Number.isInteger(count / 1000000)
        ? count / 1000000
        : (count / 1000000).toFixed(decimalPoint)
    }M`;
  } else if (count >= 100000) {
    value = `${
      Number.isInteger(count / 100000)
        ? count / 100000
        : (count / 100000).toFixed(decimalPoint)
    }L`;
  } else if (count >= 1000) {
    value = `${
      Number.isInteger(count / 1000)
        ? count / 1000
        : (count / 1000).toFixed(decimalPoint)
    }K`;
  }
  return value;
};

export const hideMoreText = (text: string, substring = 25) => {
  if (typeof text === 'string' && text.length > substring) {
    return `${text.substring(0, substring)}...`;
  } else return text;
};

export const uniqData = (a, key) => {
  const seen = new Set();
  return a.filter((item) => {
    const k = key(item);
    return seen.has(k) ? false : seen.add(k);
  });
};

export const validURL = (str) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(encodeURI(str));
};

export const youtubeValidate = (url) => {
  const regExp = /^(?:https?:\/\/)?(?:www\.)?youtube\.com(?:\S+)?$/;
  return url.match(regExp) && url.match(regExp).length > 0;
};

export const getAllVideo = ({ maxResults, playlistId }) => {
  return Axios.get(
    `https://content.googleapis.com/youtube/v3/playlistItems?playlistId=${playlistId}&maxResults=${maxResults}&key=${config.youtubeDataApiKey}&part=snippet%2CcontentDetails`
  ).then((response) => {
    const { data } = response;
    return data;
  });
};

export const stringToColour = function (str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
};

export const getContrastYIQ = (hexcolor: string) => {
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? 'black' : 'white';
};

export const dataURLtoFile = (dataurl: string, filename: string) => {
  const arr:any = dataurl.split(',');
    const matchResult = arr[0].match(/:(.*?);/);
    if (!matchResult) {
      throw new Error('Invalid data URL');
    }
    const mime = matchResult[1];
    const buf = Buffer.from(arr[1], 'base64');
  
    return new File([buf], filename, { type: mime });
  };

export const fileExtension = (url) => {
  // eslint-disable-next-line no-useless-escape
  return url ? url.split('.').pop().split(/\#|\?/)[0] : url;
};

export const youTubeGetID = (url) => {
  let ID = '';
  let result: any = null;
  if (url)
    if ((result = url.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/))) {
      ID = result.pop();
    } else if ((result = url.match(/youtu.be\/(.{11})/))) {
      ID = result.pop();
    }
  return ID;
};

export const serializeObjectToQuery = (obj: any) => {
  const str: string[] = [];
  for (const p in obj) {
    if (obj.hasOwnProperty(p)) {
      if (typeof obj[p] === 'boolean' || obj[p] === 0 || obj[p]) {
        str.push(encodeURI(p) + '=' + encodeURI(obj[p]));
      }
    }
  }
  if (str.length) {
    return '?' + str.join('&');
  }
  return '';
};

export const createInQuery = (data, keyName) => {
  const setKey = `&${keyName}[$in][]`;
  let q = '';
  data.forEach((element) => {
    if (element) {
      q = q + `${setKey}=${element}`;
    }
  });
  return q;
};

export const cleanObject = (obj: any) => {
  for (const propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === '' ||
      obj[propName] === 'other'
    ) {
      delete obj[propName];
    }
  }
  return obj;
};

export const getFormData = (object: any) => {
  const cleanedObject = cleanObject(object);
  const formData = new FormData();
  Object.keys(cleanedObject).forEach((key) =>
    (Array.isArray(cleanedObject[key])
      ? cleanedObject[key]
      : [cleanedObject[key]]
    ).forEach((o) => formData.append(key, o))
  );
  return formData;
};

declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean;
  }
}

export const sortByName = (data: any, key: string) => {
  return data?.sort(function (a, b) {
    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }
    return 0;
  });
};

export const materialName = (material) => {
  const {
    type,
    note,
    name,
    syllabus,
    subject,
    specialization,
    academicSession,
    year,
    institute,
  } = material;

  if (['note', 'video_material'].includes(type)) {
    if (note) {
      const {
        topics,
        chapters,
        subjectId,
        courseId,
        specializationId,
        academicSessionId,
      } = note;

      const noteName = [
        topics?.name,
        chapters?.name,
        subjectId?.name,
        courseId?.name,
        specializationId?.name,
        academicSessionId?.name,
      ].filter(Boolean); // filter out any empty values

      return noteName?.join(' - ') || name || '';
    } else {
      return name || '';
    }
  } else {
    if (!syllabus) {
      return name || '';
    }
    const { subjectId, specializationId, academicSessionId } = syllabus;

    const otherName = [
      subjectId?.length ? subjectId[0]?.name : subject?.name,
      specializationId?.length
        ? specializationId[0]?.name
        : specialization?.length
        ? specialization[0]?.name
        : '',
      type !== 'pyq' && academicSessionId?.length
        ? academicSessionId[0]?.name
        : academicSession?.length
        ? academicSession[0]?.name
        : '',
      year?.year,
      institute?.length ? institute[0]?.name : '',
    ].filter(Boolean); // filter out any empty values

    return otherName?.join(' - ') || '';
  }
};

export const dateFilter = (data: any) => {
  const date = new Date(data);

  const filtered = `${('0' + date.getDate()).slice(-2)}/${(
    '0' +
    (date.getMonth() + 1)
  ).slice(-2)}/${date.getFullYear()}`;

  return filtered;
};

export const excludepages = (x) => {
  const newArr: any = [];
  let validate = true;
  if (x) {
    const z = x.replace(/,*$/, ''); //remove last comma from string
    const y = z.split(',');
    for (let i = 0; i < y.length; i++) {
      if (y[i].includes('-')) {
        validate = convertToArr(y[i], newArr);
      } else {
        newArr.push(Number(y[i]));
      }
    }
  }
  return { newArr, validate };
};

const convertToArr = (str, newArr) => {
  const arr = str.split('-');
  if (Number(arr[0]) < Number(arr[1]) && Number(arr[0])) {
    for (let i = Number(arr[0]); i <= Number(arr[1]); i++) {
      newArr.push(Number(i));
    }
    return true;
  } else {
    return false;
  }
};

export const escapeSpecialChar = (text) => {
  const newStr = text && text.replace(/[[\]{}()&%*+?.,-/\\^$|#\s\s+]/g, '-');
  return newStr;
};

export const generatePassword = (pLength = 8) => {
  const smallAlpha = 'abcdefghijklmnopqrstuvwxyz',
    integer = '123456789',
    specialChars = '!@#$%^&*_',
    capitalAlpha = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

  let password = '';
  let len = Math.ceil((pLength = pLength / 2));
  len = len - 1;

  for (let i = 0; i < len; i++) {
    password += smallAlpha.charAt(
      Math.floor(Math.random() * smallAlpha.length)
    );
    password += capitalAlpha.charAt(
      Math.floor(Math.random() * capitalAlpha.length)
    );
    password += integer.charAt(Math.floor(Math.random() * integer.length));
    password += specialChars.charAt(
      Math.floor(Math.random() * specialChars.length)
    );
  }
  password = password
    .split('')
    .sort(function () {
      return 0.5 - Math.random();
    })
    .join('');

  return password;
};

// import Router from 'solito/router';

// export const redirect = (context: any, target: string) => {
//   if (context && context.res) {
//     // server
//     // 303: "See other"
//     context.res.writeHead(303, { Location: target });
//     context.res.end();
//   } else {
//     // In the browser, we just pretend like this never even happened ;)
//     Router.replace(target);
//   }
// };

export const toggleFullScreen = () => {
  const doc = window.document;
  const docEl = doc.documentElement;

  if (docEl.requestFullscreen) {
    if (!doc.fullscreenElement) {
      docEl.requestFullscreen().catch(() => {
        // console.log(
        //   `Error attempting to enable full-screen mode: ${err.message} (${err.name})`
        // );
      });
    } else {
      if (doc.exitFullscreen) {
        doc.exitFullscreen();
      }
    }
  }
};

export const convertToQueryString = (paramsObj, searchApi = true) => {
  let queryString = '';
  if (paramsObj) {
    Object.keys(paramsObj).forEach((key) => {
      paramsObj[key]?.forEach((id) => {
        const setKey = `&${key}[$in]`;
        queryString += `${searchApi ? key : setKey}=${id}&`;
      });
    });
    // Remove the trailing '&' character
    queryString = queryString.slice(0, -1);
  }
  return queryString;
};

function hashCode(str) {
  // java String#hashCode
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

function intToRGB(i) {
  const c = (i & 0x00ffffff).toString(16).toUpperCase();
  return '00000'.substring(0, 6 - c.length) + c;
}
export function stringToColor(str) {
  return `#${intToRGB(hashCode(str))}`;
}

export function getFormattedRoleName(memberData) {
  const { roleName, owner, moderator } = memberData;

  if (roleName) {
    switch (roleName) {
      case 'trainee':
        return 'Trainee';
      case 'trainer':
        return 'Trainer';
      case 'primaryCoordinator':
        return 'Primary Coordinator';
      case 'centerCoordinator':
        return 'Center Coordinator';
      case 'Co-cordinator':
        return 'Co-cordinator';
      case 'Course Co- Course Assistant Co-Coordinator':
        return 'Course Co- Course Assistant Co-Coordinator'; 
      case 'Resource person':
          return 'Resource person'; 
      case 'Office staff':
          return 'Office staff';
      case 'Participants':
          return 'Participants';
      default:
        break;
    }
  } else {
    if (owner) {
      return 'Owner';
    } else if (moderator) {
      return 'Moderator';
    }
  }

  return '';
}

import { differenceInMinutes, differenceInHours, isYesterday, format } from 'date-fns';

export const formatDate = (_date: Date): string => {
  const date=new Date(_date);
  const now = new Date();

  // Difference in minutes
  const minutes = differenceInMinutes(now, date);

  // Less than a minute
  if (minutes < 1) {
    return "Just now";
  }

  // Less than an hour
  if (minutes < 60) {
    return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
  }

  // Less than a day
  if (minutes < 1440) {
    const hours = differenceInHours(now, date);
    return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
  }

  // Yesterday
  if (isYesterday(date)) {
    return "Yesterday";
  }

  // Format as "day month" (e.g., "1 April")
  return format(date, "d MMMM");
};

function arrayBufferToBase64(arrayBuffer: ArrayBuffer): string {
  const buffer = Buffer.from(arrayBuffer);
  return buffer.toString('base64');
}


export const fetchFileFromUrl = async (url: string) => {  
      // Fetch the file content (base64-encoded) from the provided URI
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Failed to fetch file (${response.status} ${response.statusText})`);
      }
      const arrayBuffer: ArrayBuffer = await response.arrayBuffer();

      // Convert array buffer to base64 string using Buffer
      const base64Content: string = arrayBufferToBase64(arrayBuffer);

      // Extract the file type from the response headers
      const contentType: string | null = response.headers.get('content-type');
      const type: string = contentType || 'application/octet-stream';

      // Extract the filename from the URL
      const name: string = url.substring(url.lastIndexOf('/') + 1);
     
      // Return the File object and type in the response
      return { type, name, uri: `data:image/jpeg;base64,${base64Content}` };
    
}

export const strToBase64 = (str: string): string => {
  if (!str) return str;
  return Buffer.from(str, 'utf8').toString('base64');
};

export const base64Str = (base64: string): string => {
  if (!/^[A-Za-z0-9+/]*={0,2}$/.test(base64)) {
    return base64;
  }
  try {
    return Buffer.from(base64, 'base64').toString('utf8');
  } catch (error) {
    console.error('Error decoding base64:', error);
    return base64;
  }
};

export const createUrlWithParams=(path: string, params: Record<string, any> | null = null): string=> {
  if (!params || Object.keys(params).length === 0) {
      return path; // Return the path as-is if params are null or an empty object
  }

  const url = new URL(path, 'http://example.com'); // Base URL for relative paths
  Object.entries(params)
      .filter(([, value]) => value !== undefined) // Filter out undefined values
      .forEach(([key, value]) => {
          url.searchParams.append(key, value.toString());
      });

  return url.pathname + url.search;
}