import React from 'react';
import { useWindowDimensions } from 'react-native';
import { Image, styled, Text, useSx, View } from 'dripsy';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { Link } from 'app/components/link';


const TitleText = styled(Text)({
    fontFamily: 'Merriweather-Bold',
  })
  
  const TextParagraph = styled(Text)({
    fontFamily: 'Montserrat',
  })
  

const GennoctuaFooter = () => {
    const sx = useSx();
    const { width } = useWindowDimensions();
    const isMobile = width < 400;
    const isTablet = width >= 400 && width < 950;

    // const columns = [
    //     { title: 'Company', items: ['Career', 'About Us', 'Blog', 'Feature', 'Story'] },
    //     { title: 'Design', items: ['2D Designer', '3D Designer', 'Collabration', 'Tools', 'Important Link'] },
    //     { title: 'Links', items: ['Projects', 'Contact', 'Send Email', 'Fiveer', 'upWork'] },
    //     { title: 'Material', items: ['Company Profile', 'Asset', 'Location Map', 'Estimate Cost', 'Default Quotation'] },
    // ];

    const columns = [
        {
            title: 'Company',
            items: [
                { title: 'Career', value: 'Career' },
                { title: 'About Us', value: 'About Us' },
                { title: 'Blog', value: 'Blog', active: true },
                { title: 'Feature', value: 'Feature' },
                { title: 'Story', value: 'Story' },
            ],
        },
        {
            title: 'Design',
            items: [
                { title: '2D Designer', value: '2D Designer' },
                { title: '3D Designer', value: '3D Designer' },
                { title: 'Collaboration', value: 'Collaboration' },
                { title: 'Tools', value: 'Tools' },
                { title: 'Important Link', value: 'Important Link' },
            ],
        },
        {
            title: 'Links',
            items: [
                { title: 'Projects', value: 'Projects' },
                { title: 'Contact', value: 'Contact' },
                { title: 'Send Email', value: 'Send Email' },
                { title: 'Fiverr', value: 'Fiverr' },
                { title: 'Upwork', value: 'Upwork' },
            ],
        },
        {
            title: 'Material',
            items: [
                { title: 'Company Profile', value: 'Company Profile' },
                { title: 'Asset', value: 'Asset' },
                { title: 'Location Map', value: 'Location Map' },
                { title: 'Estimate Cost', value: 'Estimate Cost' },
                { title: 'Default Quotation', value: 'Default Quotation' },
            ],
        },
    ];

    const renderColumns = () => {
        // if (isMobile) {
        //     return columns.map((column, index) => (
        //         <View key={index} style={sx({ marginBottom: 20 })}>
        //             <Text style={sx({ fontSize: 16, fontWeight: 'bold', marginBottom: 10, textAlign:'center' })}>{column.title}</Text>
        //             {column.items.map((item, itemIndex) => (
        //                 <Link
        //                     variantType="text"
        //                     isNextLink
        //                     href={`/${item.toLowerCase()}`}
        //                     style={sx({
        //                         minWidth: [45],
        //                         marginLeft: 4,
        //                         paddingX: 0,
        //                     })}
        //                     textColor="#000"
        //                     key={itemIndex}
        //                 >
        //                     <Text style={sx({ marginBottom: 5 })}>{item}</Text>

        //                 </Link>
        //             ))}
        //         </View>
        //     ));
        // }

        if (isTablet || isMobile) {
            return (
                <View style={sx({ flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center' })}>
                    {columns.map((column, index) => (
                        <View key={index} style={sx({ width: '50%', marginBottom: 20 })}>
                            <Text style={sx({ fontSize: 16, fontWeight: 'bold', marginBottom: 10, textAlign: 'center' })}>{column.title}</Text>
                            {column.items.map((item, itemIndex) => (
                                <Link
                                    variantType="text"
                                    isNextLink
                                    disabled={!item?.active}
                                    href={`/${item.value.toLowerCase()}`}
                                    style={sx({
                                        minWidth: [45],
                                    })}
                                    textColor="#000"
                                    key={itemIndex}
                                >
                                    <Text style={sx({ marginBottom: 5 })}>{item.title}</Text>
                                </Link>
                            ))}
                        </View>
                    ))}
                </View>
            );
        }

        return (
            <View style={sx({ flexDirection: 'row', justifyContent: 'space-between' })}>
                {columns.map((column, index) => (
                    <View key={index} style={sx({ flex: 1, marginRight: index < 3 ? 10 : 0 })}>
                        <TitleText style={sx({ fontSize: 18, fontWeight: 'bold', marginBottom: 10, textAlign: 'center' })}>{column.title}</TitleText>
                        {column.items.map((item, itemIndex) => (
                            <Link
                                variantType="text"
                                isNextLink
                                disabled={!item.active}
                                href={`/${item.value.toLowerCase()}`}
                                style={sx({
                                    minWidth: [45],
                                })}
                                textColor="#000"
                                key={itemIndex}
                            >
                                <TextParagraph style={sx({ fontSize:14,  marginBottom: 5 })}>{item.title}</TextParagraph>
                            </Link>
                        ))}
                    </View>
                ))}
            </View>
        );
    };

    return (
        <View style={sx({ flex: 1, backgroundColor: 'white', padding: ['5%', '5%', '2%'], marginTop: 20 })}>
            <View style={sx({ flexDirection: ['column', 'column', 'row'] })}>
                <View
                    style={sx({
                        width: ['100%', '100%', '20%'],
                        justifyContent: 'center',
                        alignContent: 'center',
                        paddingX: [0, 20],
                        paddingY: [5, 10, 0],
                        marginBottom: [10, 10, 0],
                    })}
                >
                    <View style={sx({ flexDirection: 'row', alignItems: 'center', marginBottom: 10 })}>
                        <Image
                            source={require('../../assets/logo.png')}
                            style={{
                                width: 150,
                                height: 26,
                                marginRight: 5,
                            }}
                            resizeMode="contain"
                            alt="logo"
                        />
                        {/* <View style={sx({ width: 30, height: 30, backgroundColor: '#FFA500', borderRadius: 5, marginRight: 10 })} /> */}
                        {/* <Text style={sx({ fontSize: 18, fontWeight: 'bold' })}>GENNOCTUA</Text> */}
                    </View>
                    <TextParagraph style={sx({ marginBottom: 20, width: '100%' })}>
                        Interiorfirm is an acclaimed multidisciplinary studio specializing in interior architecture, interior design.
                    </TextParagraph>
                </View>

                <View style={sx({ flex: 1 })}>{renderColumns()}</View>
            </View>

            <View
                style={sx({
                    flexDirection: ['column', 'row'],
                    justifyContent: 'space-between',
                    alignItems: ['flex-start', 'center'],
                    marginTop: 20,
                    paddingTop: 10,
                    borderTopWidth: 1,
                    borderTopColor: '#EEEEEE',
                })}
            >
                <TextParagraph style={sx({ marginBottom: [10, 0] })}>© Copyright 2024 Gennoctua — All Rights Reserved.</TextParagraph>
                <View style={sx({ flexDirection: 'row' })}>
                    <TouchableOpacity>
                        <TextParagraph style={sx({ marginRight: 10 })}>Privacy Policy</TextParagraph>
                    </TouchableOpacity>
                    <TouchableOpacity>
                        <TextParagraph>Terms & Conditions</TextParagraph>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    );
};

export default GennoctuaFooter;