import { styled, Text, useSx, View } from 'dripsy'
import { useCalculateFontSize } from 'app/redux/hooks/use-calculate-font-size'
import { Button } from 'app/components/button'
import { Icon } from 'app/components/icon/icon'
import { useRouter } from 'solito/router'
import { Platform } from 'react-native'
import { useState } from 'react'

const TitleText = styled(Text)({
  fontFamily: 'Merriweather-Bold',
})

const TextParagraph = styled(Text)({
  fontFamily: 'Montserrat',
})

const ChooseGennoctua = () => {
  const list = [
    {
      title: 'AI-Powered Precision:',
      icon: require('../../assets/assistance.png'),
      desc: 'Get accurate visualizations and assembly instructions tailored to your specific space and furniture.',
    },
    {
      title: 'Stress-Free DIY',
      icon: require('../../assets/assembling.png'),
      desc: 'With step-by-step guidance, avoid the common frustrations of DIY projects and enjoy the creative process.',
    },
    {
      title: 'Inclusive Design',
      icon: require('../../assets/assistance.png'),
      desc: 'Our tools are accessible, easy to use, and available in multiple languages, making DIY home projects enjoyable for everyone.',
    },
  ]
  const sx = useSx()
  const getFontSize = useCalculateFontSize()
  const { push } = useRouter()

  return (
    <View
      style={sx({
        width: '100%',
        minHeight: ['55vh'],
        flexDirection: 'column',
        paddingX: ['5%', '5%', '10%'],
        backgroundColor: '#F8FBFA',
        paddingTop: 20,
      })}
    >
      <View
        style={sx({
          width: '100%',
          height: 46,
        })}
      >
        <TitleText
          style={sx({
            fontSize: getFontSize(100, 20),
            fontWeight: '700',
            textAlign: 'left',
            lineHeight: '46px',
            letterSpacing: '-0.02em',
            color: '$primary',
          })}
        >
          Why Choose Gennoctua?
        </TitleText>
      </View>
      <View
        style={sx({
          flexDirection: ['column', 'column', 'row'],
          flex: 1,
          height: 'calc(100% - 46px)',
          justifyContent: 'center',
        })}
      >
        {list.map((item, index) => {
          return <SencondCard key={index} item={item} index={index} />
        })}
      </View>
      <View
        style={sx({
          width: '100%',
          justifyContent: 'center',
          alignItems: ['flex-start', 'flex-start', 'center'],
        })}
      >
        <TitleText
          style={sx({
            fontSize: getFontSize(70, 20),
            fontWeight: '700',
            textAlign: 'left',
            lineHeight: '46px',
            letterSpacing: '-0.02em',
          })}
        >
          Get Started with Home Makeover
        </TitleText>
        <View
          style={sx({
            height: [10, 20],
          })}
        ></View>
        <Button
          style={sx({
            height: 40,
            maxWidth: 350,
            minWidth: [200, 250],
            backgroundColor: '#2D1D1D',
            padding: 10,
          })}
          textStyle={{
            fontSize: 14,
            fontFamily: 'Montserrat',
          }}
          onPress={() => {
            push('/account/signup')
          }}
          endIcon={
            <Icon
              name={'arrow'}
              fill={['#FFF']}
              style={{
                transform: [{ rotate: '90deg' }],
              }}
            />
          }
        >
          Sign Up Now
        </Button>
        <View
          style={sx({
            height: [10, 20],
          })}
        ></View>
      </View>
    </View>
  )
}
export default ChooseGennoctua

const SencondCard = ({ item, index }) => {
  const [isHovered, setIsHovered] = useState(false);
  const sx = useSx()
  const getFontSize = useCalculateFontSize()
  return (
    <View
    {...(Platform.OS === 'web' ? {
      onMouseEnter: () => setIsHovered(true),
      onMouseLeave: () => setIsHovered(false)
    } : {})}
      key={`${index}-index-data`}
      style={sx({
        ...{
          // both case
          height: [150, 180, 270],
          width: ['100%', '100%', '33.33%'],
          maxWidth: [300],
          marginTop: [0, 0, '5%'],
          padding: 20,
          margin: 10,
          justifyContent: 'center',
          alignItems: 'flex-start',
          borderTopLeftRadius: 7,
          borderBottomLeftRadius: 50,
          borderTopRightRadius: 50,
          borderBottomRightRadius: 7,
          display: ['none', 'none', 'flex'],
          borderWidth: 1,
          borderColor: 'transparent',
          transition: 'border 0.3s ease', // Smooth transition
        },
        ...(isHovered
          ? {
              borderWidth: 1,
              borderColor: '#785840',
            }
          : {}),
      })}
    >
      <TextParagraph
        style={sx({
          fontSize: 24,
          fontWeight: '600',
          textAlign: 'left',
          color: '#030605',
          marginBottom: 5,
        })}
      >
        {item.title}
      </TextParagraph>
      <TextParagraph
        style={sx({
          fontSize:  16,
          fontWeight: '500',
          textAlign: 'left',
          lineHeight: '24px',
          letterSpacing: '-0.02em',
          color: '#5C5E5E',
        })}
      >
        {item.desc}
      </TextParagraph>
    </View>
  )
}
