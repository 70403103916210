import { Image, styled, Text, useSx, View } from "dripsy"
import Layout from "../layout"
import { useCalculateFontSize } from "app/redux/hooks/use-calculate-font-size";
import { Button } from "app/components/button";
import { Icon } from "app/components/icon/icon";
import { useRouter } from "solito/router";


const TitleText = styled(Text)({
    fontFamily: 'Merriweather-Bold',
  })
  
  const TextParagraph = styled(Text)({
    fontFamily: 'Montserrat',
  })

const UserReview = () => {
    const sx = useSx();
    const getFontSize = useCalculateFontSize();
    const { push } = useRouter()

    return (
        <View style={sx({
            width: '100%',
            flexDirection: 'column',
            paddingY:[5,10]
        })}>
            <View style={sx({
                width: '100%',
                height: 46,
                marginBottom:[5,10,15,20]
            })}>
                <TitleText
                    style={sx(
                        {
                            fontSize: getFontSize(100, 20),
                            fontWeight: '700',
                            textAlign: ['center'],
                            lineHeight: '46px',
                            letterSpacing: '-0.02em',
                            color: '$primary',
                        }
                    )}
                >
                    Real Users, Real Results
                </TitleText>
            </View>

            <View style={
                sx({
                    display: ['flex', 'flex'],
                    width: '100%',
                    flex: 1,
                    alignItems:'center',
                    padding:'3%'
                })
            }>
                <Image
                    resizeMode={'stretch'}
                    style={[sx({ width: ['100%'], height: [150,200,300,300,400],maxWidth:1400 })]}
                    source={require('../../assets/landingPage/Frame.webp')}
                    resizeMethod={'scale'}
                    alt='aboutUs'
                />
            </View>
            <View
                style={sx({
                    width: "100%",
                    justifyContent: 'center',
                    alignItems: ['center']
                })}
            >
                <View style={sx({
                    width: ['80%', '80%', '60%'],
                    marginY: [10, 20, 20]
                })}>
                    <TextParagraph style={sx({
                        textAlign: 'center',
                        fontSize: getFontSize(60, 12),
                        fontWeight: '500',
                    })}>
                        Hear from homeowners across the U.S. who have transformed their spaces using Gennoctua’s AI tools. From small apartments to large homes, our users love the simplicity and accuracy we bring to DIY home design.                    </TextParagraph>
                </View>

                <TitleText

                    style={sx(
                        {
                            fontSize:  getFontSize(70, 20),
                            fontWeight: '400',
                            textAlign: 'left',
                            marginBottom: [10, 20, 20]
                        }
                    )}
                >
                    See Success Stories
                </TitleText>

                <Button
                   style={sx({
                    height: 40,
                    maxWidth: 350,
                    minWidth: [200, 200],
                    backgroundColor: '#2D1D1D',
                    padding: 10,
                  })}
                  textStyle={{
                    fontSize: 14,
                    fontFamily: 'Montserrat',
                  }}
                    onPress={() => {
                        push('/account/signup')
                    }}
                    endIcon={ <Icon name={'arrow'} fill={['#FFF']} style={{
                        transform: [{ rotate: '90deg' }],
                    }} />}
                >
                    
                            Read User Reviews
                      
                </Button>
            </View>

        </View>

    )
}
export default UserReview;